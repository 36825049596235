import React from "react"
import { Link } from "gatsby"
import { slide as Menu } from "react-burger-menu"
import {
  FaLaptopCode,
  FaTools,
  FaInbox,
  FaCogs,
  FaHome,
  FaFacebook,
  FaGithub,
  FaLinkedin,
} from "react-icons/fa"
const BurgerMenu = () => {
  // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
  return (
    <Menu width={"100%"}>
      <Link
        className="mobile-item"
        activeClassName="active-menu-item-mobile"
        to="/"
      >
        <FaHome />
        Home
      </Link>

      <Link
        className="mobile-item"
        activeClassName="active-menu-item-mobile"
        to="/about"
      >
        <FaLaptopCode />
        About
      </Link>
      <Link
        className="mobile-item"
        activeClassName="active-menu-item-mobile"
        to="/my-work"
      >
        <FaTools />
        My Work
      </Link>
      <Link
        className="mobile-item"
        activeClassName="active-menu-item-mobile"
        to="/settings"
      >
        <FaCogs />
        Settings
      </Link>
      <Link
        className="mobile-item"
        activeClassName="active-menu-item-mobile"
        to="/contact-me"
      >
        <FaInbox />
        Contact
      </Link>
      
      <div className="mobile-socials">
        {/* <a className="phone-link" href="tel:7417471796"><FaPhone/>7417471796</a>  */}
        <a
          href="https://github.com/frankagathos"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FaGithub />
        </a>
        <a
          href="https://uk.linkedin.com/in/frantzeskos-agathos-42401a165"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FaLinkedin />
        </a>
        <a
          href="https://www.facebook.com/frank.agathos"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FaFacebook />
        </a>
      </div>
    </Menu>
  )
}

export default BurgerMenu
