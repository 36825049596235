import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { FaFacebook, FaGithub, FaLinkedin } from "react-icons/fa"
import Logo from "./logo"

interface HeaderProps {
  siteTitle?: string
  bg?: string
  menuFixed?:boolean
}

const Header: React.FC<HeaderProps> = ({ siteTitle, bg , menuFixed }) => {
  return (
    <header>
      <div className={`main-menu container ${ menuFixed?'menu-fixed-for-desktop':''}`}>
        <div className="menu-left">
          <Logo />
        </div>
        <div className="menu-center">
          <Link to="/about" activeClassName="active-menu-item">
            About
          </Link>
          <Link to="/my-work" activeClassName="active-menu-item">
            My Work
          </Link>
          <Link to="/contact-me" activeClassName="active-menu-item">
            Contact
          </Link>
        </div>
        <div className="menu-right">
          <a
            href="https://github.com/frankagathos"
            rel="noopener noreferrer"
            target="_blank"
            className="hidden-text"
          >
            Github
            <FaGithub />
          </a>
          <a
            href="https://uk.linkedin.com/in/frantzeskos-agathos-42401a165"
            rel="noopener noreferrer"
            target="_blank"
            className="hidden-text"
          >
            Linkedin
            <FaLinkedin />
          </a>
          <a
            href="https://www.facebook.com/frank.agathos"
            rel="noopener noreferrer"
            target="_blank"
            className="hidden-text"
          >
            Facebook
            <FaFacebook />
          </a>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  bg: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  bg: `#333`,
}

export default Header
