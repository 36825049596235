import React, { useContext } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { CircleArrow as ScrollUpButton } from "react-scroll-up-button"
import Header from "./header"
import "../scss/layout.scss"
import SettingsBtn from "./settings-button"
import BurgerMenu from "./burger-menu"
import CookieConsent from "react-cookie-consent"
import { Location } from "@reach/router"
import {
  GlobalStateContext,
} from "../context/GlobalContextProvider"
import { GlobalState } from "../types/types"

const Layout: React.FC = ({ children }) => {

  const state = useContext(GlobalStateContext) as GlobalState

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} menuFixed={state.menuFixedForDesktop ? true : false} />
      <BurgerMenu />
      {/* <CookieConsent
        location="bottom"
        buttonText="I Understand"
        cookieName="myAwesomeCookieName2"
        style={{
          background: "#171a17",
          fontFamily: "Barlow",
          padding: "0px 4em 0px 4em",
          borderTop: "1px solid #F8F8FF",
        }}
        buttonStyle={{
          color: "#43d58c",
          background: "none",
          border: "none",
          fontSize: "16px",
          borderRadius: "5px",
          padding: "15px 20px",
          hovered: "color:red;",
        }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent> */}
      <div>
        <main className={state.menuFixedForDesktop ? 'menu-fixed-for-desktop' : ''}>{children}</main>
        <footer className="container">
          Copyright © {new Date().getFullYear()} Frantzeskos Agathos. All Rights
          Reserved.
        </footer>
      </div>
      <ScrollUpButton
        StopPosition={0}
        ShowAtPosition={200}
        EasingType="easeOutCubic"
        AnimationDuration={500}
        ContainerClassName="ScrollUpButton__Container"
        TransitionClassName="ScrollUpButton__Toggled"
        style={{
          bottom: "90px",
          width: "50px",
          height: "50px",
          background: "#333",
          zIndex: 5,
          border: "0px solid #000",
        }}
        ToggledStyle={{}}
      ></ScrollUpButton>

      {/*So that it doesn't compile*/}
      <Location>
        {({ location }) => {
          const currentLocation = location.pathname
          if (currentLocation === "/settings") {
            return null
          } else {
            return <SettingsBtn></SettingsBtn>
          }
        }}
      </Location>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
