import React from "react"
import { FaCogs } from "react-icons/fa"
import { Tooltip } from "@chakra-ui/core"
import { Link } from "gatsby"


const SettingsBtn = () => {

  return (

    <Tooltip showDelay={200} aria-label="settings" label="App Settings" placement="right">

      <Link
        className="settings-button"
        activeClassName="active-menu-item-mobile"
        to="/settings"
      >
        <FaCogs />
      </Link>

    </Tooltip>

  )
}



export default SettingsBtn
