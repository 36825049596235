import { Link } from "gatsby"
import { Random } from "react-animated-text"
import React, { PureComponent } from "react"

class Logo extends PureComponent {
  render() {
    return (
      <Link activeClassName="active-menu-item" className="logo" to="/">
        <div>
          <span>Frank Agathos</span>
        </div>
        <div>
          <span className="span2">
            <Random
              text="Front end developer"
              iterations={1}
              effect="verticalFadeIn"
              effectChange={2}
              effectDirection="up"
            />
            {/* Front end developer */}
          </span>
        </div>
      </Link>
    )
  }
}

export default Logo
